<rspl-card [cardTitle]="cardTitle">
  <div *ngIf="showItems">
    <mat-button-toggle-group
      fxFlex="100"
      [(ngModel)]="mode"
      (change)="changeMode()"
    >
      <mat-button-toggle fxFlex="50" value="items"> Items </mat-button-toggle>
      <mat-button-toggle fxFlex="50" value="spec"> Spec </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <rspl-card class="add-item-wrapper" *ngIf="mode === 'items'">
    <div
      class="add-item"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="10px"
    >
      <rspl-item-lookup
        fxFlex
        [currentId]="newItem.item?.id"
        (onItemSelected)="selectItem($event)"
        label="Select an Item"
        addNewLabel="Create new Item"
        [onAddNew]="onAddNewItem.bind(this)"
        class="item-lookup"
        [shouldSort]="false"
      ></rspl-item-lookup>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            type="button"
            mat-mini-fab
            (click)="newItem.quantity = newItem.quantity - 1"
            [disabled]="newItem.quantity <= 1"
            *ngIf="!readonly"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <input
            type="tel"
            [(ngModel)]="newItem.quantity"
            (keyup)="updateItemQuantity($event)"
            (focus)="focusInput($event)"
            matInput
            class="item-quantity"
          />
          <button
            type="button"
            mat-mini-fab
            (click)="newItem.quantity = newItem.quantity + 1"
            *ngIf="!readonly"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div fxFLex fxLayoutAlign="center center">
          <button
            id="addItemButton"
            mat-raised-button
            class="primary-btn filled-btn"
            [disabled]="!newItem.item || newItem.quantity < 1"
            (click)="addToItems()"
            (keydown.enter)="preventEvent($event)"
            (keyup.enter)="addToItems()"
          >ADD</button>
        </div>
      </div>
    </div>
  </rspl-card>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="isCaptain && showBasePrice"
  >
    <span class="label">Base Price</span>
    <div fxLayout="row" fxLayoutAlign="end center">
      <span class="price-value">{{ pricing?.base | currency }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span class="label"
      >Extra Large items
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.xlarge | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('xlarge')"
        [disabled]="specification.xlarge === 0 || mode === 'items'"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">
        {{ specification.xlarge }}
      </span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('xlarge')"
        *ngIf="!readonly"
        [disabled]="mode === 'items'"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.xlarge * specification.xlarge | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span class="label"
      >Large items
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.large | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('large')"
        [disabled]="specification.large === 0 || mode === 'items'"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.large }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('large')"
        *ngIf="!readonly"
        [disabled]="mode === 'items'"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.large * specification.large | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span class="label"
      >Medium items
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.medium | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('medium')"
        [disabled]="specification.medium === 0 || mode === 'items'"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.medium }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('medium')"
        *ngIf="!readonly"
        [disabled]="mode === 'items'"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.medium * specification.medium | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="showSmall"
  >
    <span class="label"
      >Bags and Boxes
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.small | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('small')"
        [disabled]="specification.small === 0"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.small }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('small')"
        *ngIf="!readonly"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.small * specification.small | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="showStaircases"
  >
    <span class="label"
      >Staircases
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.staircases | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('staircases')"
        [disabled]="specification.staircases === 0"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.staircases }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('staircases')"
        *ngIf="!readonly"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.staircases * specification.staircases | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="showElevator && pricing?.elevator !== null"
  >
    <span class="label"
      >Elevator
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.elevator | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('elevator')"
        [disabled]="specification.elevator === 0"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.elevator }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('elevator')"
        *ngIf="!readonly"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.elevator * specification.elevator | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="showDisassembly"
  >
    <span class="label"
      >Disassembly
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.disassembly | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('disassembly')"
        [disabled]="specification.disassembly === 0"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.disassembly }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('disassembly')"
        *ngIf="!readonly"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.disassembly * specification.disassembly | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="showRecycling"
  >
    <span class="label"
      >Recycling
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.recycling | currency }})</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-mini-fab
        (click)="removeItem('recycling')"
        [disabled]="specification.recycling === 0"
        *ngIf="!readonly"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <span class="value">{{ specification.recycling }}</span>
      <button
        type="button"
        mat-mini-fab
        (click)="addItem('recycling')"
        *ngIf="!readonly"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        pricing.recycling * specification.recycling | currency
      }}</span>
    </div>
  </div>
  <div
    class="estimate-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="isCaptain && pricing?.fuelFee"
  >
    <span class="label"
      >Fuel Fee
      <span *ngIf="isCaptain && showPricing" class="price"
        >({{ pricing?.fuelFee }}%)</span
      ></span
    >
    <div fxLayout="row" fxLayoutAlign="end center">
      <span *ngIf="isCaptain && pricing" class="price-value">{{
        fuelFee | currency
      }}</span>
    </div>
  </div>
  <mat-divider *ngIf="showPrice"></mat-divider>
  <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="showPrice">
    <h2>Estimated{{ isCaptain ? ' Pickup' : '' }} Cost:</h2>
    <h2>{{ !pricing ? '' : (totalCostEstimate | currency) }}</h2>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between"
    *ngIf="isCaptain && showPrice && pricing && pricing?.bookingFee"
  >
    <h2>Booking Fee:</h2>
    <h2>{{ pricing.bookingFee | currency }}</h2>
  </div>
  <mat-divider *ngIf="showPrice && isCaptain"></mat-divider>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between"
    *ngIf="
      isCaptain &&
      showPrice &&
      pricing &&
      pricing?.bookingFee &&
      totalCostEstimate
    "
  >
    <h2>Estimated Total Cost of Service:</h2>
    <h2>{{ (pricing.bookingFee || 0) + totalCostEstimate | currency }}</h2>
  </div>
  <rspl-card
    *ngIf="items.length > 0"
    style="margin-bottom: 10px; display: block"
  >
    <div fxLayout="row wrap" fxLayoutGap="5px">
      <div *ngFor="let item of items; let i = index" class="added-item">
        <span> {{ item.quantity }} x </span>
        <span>
          {{ item.item.name }}
        </span>
        <button mat-icon-button (click)="removeFromItems(i)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </rspl-card>

  <div fxLayout="row" fxLayoutAlign="space-between">
    <p
      class="mat-error"
      *ngIf="
        showErrors &&
        ((showSmall && specification.isEmptyBig()) ||
          (!showSmall && specification.isEmpty()))
      "
    >
      Quote can not be empty
    </p>
  </div>
  <ng-content></ng-content>
</rspl-card>
