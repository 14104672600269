<ng-template #Estimate>
  <table class="static-table full-width {{ app }}" cellspacing="0">
    <thead>
      <tr>
        <th>Items</th>
        <th [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          Quantity
        </th>
        <th
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          Unit price
        </th>
        <th style="text-align: right" *ngIf="!isCharityApp && showPrices">
          Price
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!isCharityApp && showPrices">
        <td>Base price</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">1</td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.base | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ pricing.base | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || initialSpecification.xlarge > 0">
        <td>Extra Large items</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ specification.xlarge }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.xlarge"
            (keyup)="specification.xlarge = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.xlarge | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.xlarge * pricing.xlarge | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || initialSpecification.large > 0">
        <td>Large items</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ specification.large }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.large"
            (keyup)="specification.large = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.large | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.large * pricing.large | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || initialSpecification.medium > 0">
        <td>Medium items</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ specification.medium }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.medium"
            (keyup)="specification.medium = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.medium | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.medium * pricing.medium | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || initialSpecification.small > 0">
        <td>Bags and Boxes</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ specification.small }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.small"
            (keyup)="specification.small = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.small | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.small * pricing.small | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || initialSpecification.staircases > 0">
        <td>Staircases</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ specification.staircases }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.staircases"
            (keyup)="specification.staircases = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.staircases | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.staircases * pricing.staircases | currency }}
        </td>
      </tr>
      <tr *ngIf="pricing.elevator !== null && (showZeros || initialSpecification.elevator > 0 || specification.elevator > 0)">
        <td>Elevator</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ isDonorApp ? specification.elevator > 0 ? 1 : 0 : specification.elevator }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.elevator"
            (keyup)="specification.elevator = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ (isDonorApp ? specification.elevator * pricing.elevator : pricing.elevator) | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.elevator * pricing.elevator | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || initialSpecification.disassembly > 0">
        <td>Disassembly</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ specification.disassembly }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.disassembly"
            (keyup)="specification.disassembly = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ pricing.disassembly | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.disassembly * pricing.disassembly | currency }}
        </td>
      </tr>
      <tr *ngIf="showZeros || specification.recycling > 0">
        <td>Recycling</td>
        <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
          <span *ngIf="!editable">
            {{ isDonorApp ? specification.recycling > 0 ? 1 : 0 : specification.recycling }}
          </span>
          <input
            *ngIf="editable"
            type="tel"
            [(ngModel)]="specification.recycling"
            (keyup)="specification.recycling = checkValue($event)"
            (focus)="focusInput($event)"
            (ngModelChange)="updatePrice()"
          />
        </td>
        <td
          style="text-align: right"
          *ngIf="!isCharityApp && showPrices && showUnitPrices"
        >
          {{ (isDonorApp ? specification.recycling * pricing.recycling : pricing.recycling) | currency }}
        </td>
        <td style="text-align: right" *ngIf="!isCharityApp && showPrices">
          {{ specification.recycling * pricing.recycling | currency }}
        </td>
      </tr>
      <tr *ngIf="!isCharityApp && (pricing.fuelFee || 0) > 0">
        <td>Fuel Fee</td>
        <td *ngIf="!showPrices || showUnitPrices"></td>
        <td
          *ngIf="showPrices"
          [ngStyle]="{ 'text-align': design2 && editable ? 'center' : 'right' }"
        >
          {{ pricing.fuelFee }}%
        </td>
        <td *ngIf="showPrices" style="text-align: right">
          {{ fuelFee | currency }}
        </td>
      </tr>
      <tr *ngIf="isDonorApp && showBookingFee === 'regular' && bookingFee">
        <td colspan="3" *ngIf="showUnitPrices" [innerHTML]="bookingLabel"></td>
        <td colspan="2" *ngIf="!showUnitPrices" [innerHTML]="bookingLabel"></td>
        <td style="text-align: right">
          {{ (bookingFee || 0) | currency }}
        </td>
      </tr>
      <tr
        *ngIf="
          !isCharityApp && showPrices && gratuity !== undefined && gratuity > 0
        "
      >
        <td>Tip</td>
        <td style="text-align: right" *ngIf="showUnitPrices"></td>
        <td style="text-align: right"></td>
        <td style="text-align: right">{{ gratuity / 100 | currency }}</td>
      </tr>
      <tr class="total" *ngIf="!isCharityApp && showPrices && !collapsable" class="primary-row top-border-row">
        <td colspan="3" *ngIf="showUnitPrices">{{ totalLabel }}</td>
        <td colspan="2" *ngIf="!showUnitPrices">{{ totalLabel }}</td>
        <td style="text-align: right">
          {{ ((donationPrice || 0) + (includeBookingInTotal ? (bookingFee || 0) : 0)) | currency }}
        </td>
      </tr>
      <tr class="total" *ngIf="isDonorApp && showBookingFee === 'title' && bookingFee" class="primary-row">
        <td colspan="3" *ngIf="showUnitPrices" [innerHTML]="bookingLabel"></td>
        <td colspan="2" *ngIf="!showUnitPrices" [innerHTML]="bookingLabel"></td>
        <td style="text-align: right">
          {{ (bookingFee || 0) | currency }}
        </td>
      </tr>
      <tr class="total" *ngIf="isDonorApp && showBookingFee === 'title' && bookingFee" class="primary-row top-border-row">
        <td colspan="3" *ngIf="showUnitPrices">Estimated Total Cost of Service</td>
        <td colspan="2" *ngIf="!showUnitPrices">Estimated Total Cost of Service</td>
        <td style="text-align: right">
          {{ ((donationPrice || 0) + (bookingFee || 0)) | currency }}
        </td>
      </tr>
      <tr class="total" *ngIf="isDonorApp && showTodaysCharges" class="success-row">
        <td colspan="3" *ngIf="showUnitPrices">Today's Charges</td>
        <td colspan="2" *ngIf="!showUnitPrices">Today's Charges</td>
        <td style="text-align: right">
          {{ (bookingFee || 0) | currency }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
<ng-container [ngTemplateOutlet]="Estimate" *ngIf="!collapsable"></ng-container>
<mat-accordion *ngIf="collapsable">
  <mat-expansion-panel
    #expansionPanel
    [hideToggle]="true"
    [disabled]="disabled"
  >
    <mat-expansion-panel-header>
      <mat-panel-title fxLayout="column" fxLayoutAlign="grow">
        <div fxFlex fxLayoutAlign="space-between" fxLayout="row">
          <span> {{ totalLabel }}: </span>
          <span>
            <div fxFlex fxLayoutAlign="center start" *ngIf="!disabled">
              <mat-icon>{{
                expansionPanel.expanded
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"
              }}</mat-icon>
            </div>
            {{ donationPrice | currency }}
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="Estimate"></ng-container>
  </mat-expansion-panel>
</mat-accordion>
