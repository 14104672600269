import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonActivity, ENVIRONMENT, Environment, PageActivity } from '@domains';
import { AppService } from '@donor/app.service';
import { LeadsService, LocalStorageService } from '@rspl-api';
import { DesignService, ResponsiveService } from '@rspl-ui';
import { BaseCreateComponent, FlowStep } from '../base-create.component';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent
  extends BaseCreateComponent
  implements AfterViewInit
{
  public form!: FormGroup<{
    donor: FormGroup<{
      name: FormControl<string>;
      email: FormControl<string>;
      phone: FormControl<string>;
    }>;
    address?: FormGroup<{
      state: FormControl<string>;
    }>;
    instructions: FormControl<string | null>;
    donationItems: FormControl<string | null>;
  }>;
  @ViewChild('nameInput') nameInput!: ElementRef<HTMLInputElement>;

  smsApproval = true;

  constructor(
    public override service: AppService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected override dialog: MatDialog,
    protected override leadsService: LeadsService,
    protected override localStorage: LocalStorageService,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      service,
      route,
      router,
      dialog,
      leadsService,
      localStorage,
      designService,
      responsiveService,
      FlowStep.AdditionalInformation,
      environment
    );
  }

  init(): void {
    this.smsApproval = this.service.donation?.meta?.smsApproval !== undefined ? this.service.donation?.meta?.smsApproval : true;
    this.service
      .createLeadActivity(this.lead?.id, PageActivity.DONOR_INFO_PAGE)
      .subscribe();
    this.initForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.nameInput?.nativeElement.focus();
    });
  }

  private initForm(): void {
    this.form = new FormGroup({
      donor: new FormGroup({
        name: new FormControl(this.service.donation?.donor?.name || '', [
          Validators.required,
          Validators.pattern(/^[^\s]+.*$/),
        ]),
        email: new FormControl(this.service.donation?.donor?.email || '', [
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
          ),
        ]),
        phone: new FormControl(this.service.donation?.donor?.phone || '', [
          Validators.required,
          Validators.pattern(/^([0-9]{10})$/),
        ]),
      }),
      instructions: new FormControl(this.service.donation?.instructions),
      donationItems: new FormControl(this.service.donation?.donationItems),
    });
  }

  saveData(): ButtonActivity {
    const data = this.form.getRawValue();
    Object.assign(this.service.donation || {}, data);
    this.service.donation.meta.smsApproval = this.smsApproval;
    this.service
      .createLeadActivity(this.lead?.id, ButtonActivity.SMS_CHECKBOX,
        this.smsApproval ? 'checked' : 'unchecked')
      .subscribe();
    return ButtonActivity.DONOR_INFO_SUBMIT;
  }

  get valid(): boolean {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  public setSelectedState(e: any): void {
    if (e.abbreviation) {
      this.form.patchValue({
        address: { state: e.abbreviation },
      });
    }
  }

  next(): void {
    this.router.navigate(['/', 'confirm', this.flowType, this.flowId], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  back() {
    if (this.service.donation && this.form && this.valid) {
      Object.assign(this.service.donation, this.form.getRawValue());
    }
    this.router.navigate(['/', 'place-time', this.flowType, this.flowId], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  showError(): void {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.DONOR_INFO_SUBMIT_ERROR)
      .subscribe();
  }

}
