import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Address, Environment, ENVIRONMENT, Place } from '@domains';
import { take } from 'rxjs';

@Component({
  selector: 'rspl-simple-address-input',
  templateUrl: './simple-address-input.component.html',
  styleUrls: ['./simple-address-input.component.scss'],
})
export class SimpleAddressInputComponent {
  @ViewChild('inputElement') inputEl: any;
  @ViewChildren('li') addrLis: any[] = [];
  @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>();
  @Input() label = 'Street';
  @Input() audio?: string;
  @Input() hasIcon = false;
  @Input() readonly = false;
  @Input() types: string[] = ['address'];
  @Input() set required(required: boolean) {
    if (required) {
      this.street.addValidators(Validators.required);
    } else {
      this.street.removeValidators(Validators.required);
    }
  }
  @Input() dropdownPosition: 'left' | 'right' = 'left';
  @Input() set disabled(disabled: boolean) {
    if (disabled) this.street?.disable();
    else this.street?.enable();
  }
  street = new FormControl<string | null>('', Validators.required);
  currentAddress?: Address;
  delay: any;
  loaded = false;

  @Input() set address(address: Address | undefined) {
    this.street.patchValue(address?.street || '');
    this.currentAddress = address;
  }

  addresses: Address[] = [];
  loading = false;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient
  ) {}

  getAddressesDelayed() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      this.getAddresses();
    }, 300);
  }

  getAddressesInitial() {
    if ((this.street.value?.length || 0) > 0 && !this.loaded) {
      this.getAddresses();
    }
  }

  async getAddresses() {
    this.addresses = [];
    if (this.street.value?.trim().length === 0) return;
    this.loading = true;

    this.fetchAddresses();
  }

  async select(addr: Address, event: MatOptionSelectionChange) {
    if (!event.isUserInput) return;
    this.currentAddress = addr;
    this.street.patchValue(this.currentAddress.street || '');
    this.addressChange.emit(this.currentAddress);
  }

  fetchAddresses() {
    this.http
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.street.value}.json`,
        {
          params: {
            autocomplete: true,
            country: 'us',
            language: 'en',
            limit: 10,
            proximity: 'ip',
            types: this.types.join(','),
            access_token: this.config.mapBoxAccessToken,
          },
        }
      )
      .pipe(take(1))
      .subscribe((res: { features?: Place[] }) => {
        this.addresses = [];
        res.features?.map((place) =>
          this.addresses.push({
            street: (place.address ? place.address + ' ' : '') + (place.text_en || place.text),
            city: place.context.find((c) => c.id.includes('place'))?.text_en || place.context.find((c) => c.id.includes('place'))?.text,
            state: place.context
              .find((c) => c.id.includes('region'))
              ?.short_code.replace('US-', ''),
            zip: place.context.find((c) => c.id.includes('postcode'))?.text_en || place.context.find((c) => c.id.includes('postcode'))?.text,
            lat: place.center[1],
            lng: place.center[0],
          } as Address)
        );
        this.loading = false;
        this.loaded = true;
      });
  }
}
