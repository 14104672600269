<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header title="Please fill in your information."
    subtitle="This is needed for Resupply to send you confirmation details and update notifications." [hasBack]="true"
    (onBack)="back()"></app-page-header>
  <div class="page-content" fxLayout="column" fxLayoutAlign="start start">
    <div class="donor-info step-content" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.xs="start center">
      <app-info-form [form]="form"></app-info-form>
      <mat-checkbox class="sms-checkbox" color="primary" [checked]="smsApproval" (change)="smsApproval = !smsApproval">
        I agree to the use of SMS texts to communicate with me about my appointment.
      </mat-checkbox>
      <div fxLayoutAlign="center center">
        <button id="step3Submission" class="primary-btn warn-btn filled-btn" (click)="save()">
          Next Step
        </button>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" class="progress-wrapper">
      <app-create-progress fxFlex [step]="2"></app-create-progress>
    </div>
  </div>
</ng-template>