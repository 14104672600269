import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ButtonActivity,
  Charity,
  Design,
  Donation,
  DonationDonorState,
  DonationPartnerState,
  ENVIRONMENT,
  Environment,
  googleTagManagerPageActivities,
  InputActivity,
  Lead,
  Organization,
  PageActivity,
  PartOfDay,
  Pricing,
} from '@domains';
import { Client, createInstance } from '@optimizely/optimizely-sdk';
import { IOptimizelyUserContext } from '@optimizely/optimizely-sdk/dist/optimizely_user_context';
import {
  CharityService,
  DonationsService,
  LeadsService,
  OrganizationsService,
  PartnerService,
} from '@rspl-api';
import { DesignService } from '@rspl-ui';
import { CalendarOptions, ICalendar } from 'datebook';
import * as moment from 'moment';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

declare let gtag: (event: string, event_name: string, data?: any) => void;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public donation?: Donation | Lead | any;
  public charity?: Charity | null;
  public territory?: Organization | null;
  public pricing?: Pricing;
  public zipCode?: string | null;
  public showCharityLogo = true;
  private charitySubject = new BehaviorSubject<Charity | undefined | null>(
    undefined
  );
  public charity$ = this.charitySubject.asObservable();
  private territorySubject = new BehaviorSubject<
    Organization | undefined | null
  >(undefined);
  public territory$ = this.territorySubject.asObservable();
  manualUrlParam = false;
  optimizelyMarketingData: {
    [key: string]: any;
  } = {};
  optimizely: Client;
  optimizelyUser: IOptimizelyUserContext;

  constructor(
    private donationsService: DonationsService,
    private leadsService: LeadsService,
    private charityService: CharityService,
    private partnerService: PartnerService,
    private organizationService: OrganizationsService,
    private designService: DesignService,
    @Inject(ENVIRONMENT) private environment: Environment,
    protected sanitizer: DomSanitizer
  ) {}

  reset() {
    this.donation = undefined;
    this.zipCode = undefined;
    this.pricing = undefined;
    this.charity = undefined;
    this.charitySubject.next(this.charity);
    this.territorySubject.next(this.territory);
  }

  getCharity(id: string): Observable<Charity> {
    return this.charityService.find(id, true, ['market', 'screening']).pipe(
      tap((charity: Charity) => {
        this.charity = charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  getTerritory(id: string): Observable<Organization> {
    return this.organizationService.find(id).pipe(
      tap((territory: Organization) => {
        this.territory = territory;
        this.territorySubject.next(this.territory);
      })
    );
  }

  createDonation(donation: Donation): Observable<Donation> {
    return this.donationsService.create(donation).pipe(
      tap((result: Donation) => {
        this.charity = result.charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  saveLead(lead?: Lead): Observable<Lead> {
    const req = lead?.id
      ? this.leadsService.update(lead?.id, lead)
      : this.leadsService.create(lead || {});
    return req.pipe(
      tap((result: Lead) => {
        this.donation = result;
        this.zipCode = this.donation.address?.zip;
      })
    );
  }

  assignCharityToLead(leadId: string): Observable<Lead> {
    return this.leadsService
      .assignCharity(leadId)
      .pipe(switchMap(() => this.getLead(leadId)));
  }

  getLead(id: string): Observable<Lead> {
    return this.leadsService.find(id).pipe(
      tap((result: Lead) => {
        this.donation = result;
        this.zipCode = this.donation.address?.zip;
      })
    );
  }

  submitDonation(leadId: string): Observable<Donation> {
    return this.donationsService.convertLeadToDonation(leadId).pipe(
      tap((result: Donation) => {
        this.charity = result.charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  getDonationByCode(code: string): Observable<any> {
    return this.donationsService.getDonationByCode(code).pipe(
      tap((d) => {
        this.donation = d;
        this.charity = this.donation?.charity;
        this.charitySubject.next(this.charity);
        if (!d.charity) {
          this.designService.setDesign(Design.DESIGN_2);
        }
      })
    );
  }

  updateDonationByCode(donation: Donation): Observable<Donation> {
    return this.donationsService.updateDonationByCode(donation).pipe(
      tap((d) => {
        this.donation = d;
        this.charity = this.donation?.charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  addToCalendar(donation: Donation) {
    const start = moment(donation.date);
    const end = moment(donation.date);
    if (donation.partOfDay === PartOfDay.am) {
      start.hours(8).minutes(0).seconds(0).milliseconds(0);
      end.hours(12).minutes(0).seconds(0).milliseconds(0);
    } else {
      start.hours(12).minutes(0).seconds(0).milliseconds(0);
      end.hours(17).minutes(0).seconds(0).milliseconds(0);
    }
    const config: CalendarOptions = {
      title: 'Donation: ' + donation.donationCode,
      location:
        donation?.address?.street +
        ', ' +
        donation?.address?.city +
        ', ' +
        donation?.address?.state +
        ', ' +
        donation?.address?.zip,
      description: window.location.origin + '/i/' + donation.donationCode,
      start: start.toDate(),
      end: end.toDate(),
    };
    const icalendar = new ICalendar(config);
    icalendar.download();
  }

  canEditDonation(donation?: Donation): boolean {
    return (
      !!donation && donation.partnerState !== DonationPartnerState.completed
    );
  }

  canCancelDonation(donation?: Donation): boolean {
    return (
      !!donation &&
      donation.partnerState !== DonationPartnerState.completed &&
      donation.donorState !== DonationDonorState.canceled &&
      !(donation.payment?.authCompleted || donation.payment?.completed)
    );
  }

  getDonationStep(donation: Donation) {
    if (
      donation.donorState &&
      [
        DonationDonorState.submitted,
        DonationDonorState.rescheduled,
        DonationDonorState.confirmed,
      ].includes(donation.donorState) &&
      [
        DonationPartnerState.unassigned,
        DonationPartnerState.assigned,
        DonationPartnerState.declined,
      ].includes(donation.partnerState)
    ) {
      return 0;
    } else if (
      donation.donorState &&
      [
        DonationDonorState.submitted,
        DonationDonorState.rescheduled,
        DonationDonorState.confirmed,
      ].includes(donation.donorState) &&
      [DonationPartnerState.accepted].includes(donation.partnerState)
    ) {
      return 1;
    } else if (
      donation.donorState &&
      [
        DonationDonorState.submitted,
        DonationDonorState.rescheduled,
        DonationDonorState.confirmed,
      ].includes(donation.donorState) &&
      [DonationPartnerState.en_routed, DonationPartnerState.arrived].includes(
        donation.partnerState
      )
    ) {
      return 2;
    } else if (
      donation.partnerState === DonationPartnerState.quote_sent &&
      !donation.payment?.authCompleted
    ) {
      return 3;
    } else if (
      donation.payment?.authCompleted ||
      [
        DonationPartnerState.payment_skipped,
        DonationPartnerState.en_routed_to_store,
        DonationPartnerState.en_routed_to_secondary,
        DonationPartnerState.arrived_at_store,
        DonationPartnerState.arrived_at_secondary,
        DonationPartnerState.completed,
      ].includes(donation.partnerState)
    ) {
      return 4;
    } else {
      return 5;
    }
  }

  createLeadActivity(
    leadId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity,
    value?: any
  ) {
    if (
      this.environment.production &&
      googleTagManagerPageActivities.includes(type as PageActivity) &&
      (this.environment.isProductionBuild ||
        this.environment.isDevBuild ||
        this.environment.isStagingBuild)
    ) {
      gtag('event', 'page_activity', {
        page_visited: type,
        value,
        ...(this.donation?.donor?.email || this.donation?.donor?.phone ? {
          user_email: this.donation?.donor?.email,
          user_phone: this.donation?.donor?.phone,
        } : {})
      });
    }
    if (
      Object.values(PageActivity).includes(type as PageActivity) &&
      [
        PageActivity.GOODWILL_SHOP_LANDING_PAGE,
        PageActivity.SPLIT_LANDING_PAGE,
        PageActivity.SCREENING_PAGE,
        PageActivity.DONATION_SPEC_PAGE,
        PageActivity.AVAILABILITY_PAGE,
        PageActivity.DONOR_INFO_PAGE,
        PageActivity.PAYMENT_SETUP_PAGE,
        PageActivity.SPLASH_SCREEN_PAGE,
      ].includes(type as PageActivity) &&
      this.optimizelyUser
    ) {
      this.optimizelyEvent(type, {
        lead_id: leadId,
        activity_value: value,
      });
    }
    return this.leadsService.createLeadActivity(
      leadId,
      type,
      value,
      this.isManual ? 'm-' : ''
    );
  }

  createDonationActivity(
    donationId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity,
    value?: any,
    origin?: string
  ) {
    return this.donationsService.createDonationActivity(
      donationId,
      type,
      value,
      this.isManual ? 'm-' : '',
      origin
    );
  }

  get isManual(): boolean {
    return (
      this.donation?.marketingSource?.includes('manual') || this.manualUrlParam
    );
  }

  async initOptimizely(lead: Lead, variation?: string) {
    if (!this.environment.optimizelyKey || this.optimizelyUser) return null;
    try {
      const DATAFILE_URL = `https://cdn.optimizely.com/datafiles/${this.environment.optimizelyKey}.json`;
      const response = await fetch(DATAFILE_URL);
      const datafile = await response.json();

      this.optimizely = createInstance({
        datafile,
      });

      if (!this.optimizely) {
        console.log('Optimizely init error');
        return null;
      } else {
        let marketId;
        const charityId = lead.charityId || this.charity?.id;
        if (charityId) {
          marketId = (
            await firstValueFrom(this.charityService.find(lead.charityId))
          )?.marketId;
        }
        if (lead.partnerId) {
          marketId = (
            await firstValueFrom(this.partnerService.find(lead.partnerId))
          )?.marketId;
        }
        this.optimizelyUser = this.optimizely.createUserContext(lead.id, {
          ...(variation ? { variation } : {}),
          ...(marketId ? { market_id: marketId } : {}),
          ...(charityId ? { charity_id: charityId } : {}),
          ...(lead.partnerId ? { partner_id: lead.partnerId } : {}),
          ...(lead.ownerId ? { owner_id: lead.ownerId } : {}),
          ...(lead.ownerType ? { owner_type: lead.ownerType } : {}),
        });
        const optimizelyMarketingDecision =
          this.optimizelyUser.decide('marketing');
        this.optimizelyMarketingData = optimizelyMarketingDecision.variables;
        return {
          variation: optimizelyMarketingDecision.variationKey,
          rule: optimizelyMarketingDecision.ruleKey,
        };
      }
    } catch (err) {
      return null;
    }
  }

  optimizelyEvent(
    eventName: string,
    eventTags?: {
      revenue?: number;
      value?: string;
    } & { [key: string]: any }
  ) {
    this.optimizelyUser.trackEvent(eventName, eventTags);
  }

  bypassSecurityTrustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
