import { autoserialize, autoserializeAs } from 'cerialize';
import { BaseDTO, IBaseDTO } from './base-dto';
import { NotificationTarget } from './notification-target';
import { NotificationTargetType } from './notification-target-type';
import { NotificationTrigger } from './notification-trigger';

export class Notification extends BaseDTO implements INotification {
  @autoserialize id: string;
  @autoserialize target: NotificationTarget;
  @autoserialize trigger: NotificationTrigger;
  @autoserialize template?: string;
  @autoserialize subject?: string;
  @autoserializeAs(NotificationTargetType, 'target_type')
  targetType: NotificationTargetType;

  constructor(payload: INotification) {
    super(payload);
    this.id = payload?.id?.toString();
    this.target = payload?.target;
    this.trigger = payload?.trigger;
    this.template = payload?.template;
    this.subject = payload?.subject;
    this.targetType = payload?.targetType;
  }

  public static notificationTargetTypeTriggers(
    type: NotificationTargetType
  ): NotificationTrigger[] {
    switch (type) {
      case NotificationTargetType.PUSH:
        return [
          NotificationTrigger.donation_auth_complete,
          NotificationTrigger.donation_charity_assign,
          NotificationTrigger.donation_checkout_payment,
          NotificationTrigger.donation_checkout_setup,
          NotificationTrigger.donation_code_payment,
          NotificationTrigger.donation_code_update,
          NotificationTrigger.donation_create,
          NotificationTrigger.donation_donor_accept,
          NotificationTrigger.donation_donor_cancel,
          NotificationTrigger.donation_donor_confirm,
          NotificationTrigger.donation_donor_reschedule,
          NotificationTrigger.donation_manual_payment,
          NotificationTrigger.donation_offline_payment,
          NotificationTrigger.donation_partner_accept,
          NotificationTrigger.donation_partner_arrive,
          NotificationTrigger.donation_partner_arrive_at_store,
          NotificationTrigger.donation_partner_assign,
          NotificationTrigger.donation_partner_cancel,
          NotificationTrigger.donation_partner_complete,
          NotificationTrigger.donation_partner_decline,
          NotificationTrigger.donation_partner_en_route,
          NotificationTrigger.donation_partner_en_route_to_store,
          NotificationTrigger.donation_partner_send_quote,
          NotificationTrigger.donation_partner_skip_payment,
          NotificationTrigger.donation_payment_complete,
          NotificationTrigger.donation_payment_failure,
          NotificationTrigger.donation_refund,
          NotificationTrigger.donation_review,
          NotificationTrigger.donation_setup_complete,
          NotificationTrigger.donation_submit,
          NotificationTrigger.donation_update,
          NotificationTrigger.donation_timed_assign,
          NotificationTrigger.partner_assigned_reminder,
          NotificationTrigger.partner_accepted_reminder,
          NotificationTrigger.donation_update_eta,
          NotificationTrigger.donation_code_update_request_eta,
        ];
      case NotificationTargetType.SMS:
        return [
          NotificationTrigger.donation_auth_complete,
          NotificationTrigger.donation_charity_assign,
          NotificationTrigger.donation_checkout_payment,
          NotificationTrigger.donation_checkout_setup,
          NotificationTrigger.donation_code_payment,
          NotificationTrigger.donation_code_update,
          NotificationTrigger.donation_create,
          NotificationTrigger.donation_donor_accept,
          NotificationTrigger.donation_donor_cancel,
          NotificationTrigger.donation_donor_confirm,
          NotificationTrigger.donation_donor_reschedule,
          NotificationTrigger.donation_manual_payment,
          NotificationTrigger.donation_offline_payment,
          NotificationTrigger.donation_partner_accept,
          NotificationTrigger.donation_partner_arrive,
          NotificationTrigger.donation_partner_arrive_at_store,
          NotificationTrigger.donation_partner_assign,
          NotificationTrigger.donation_partner_cancel,
          NotificationTrigger.donation_partner_complete,
          NotificationTrigger.donation_partner_decline,
          NotificationTrigger.donation_partner_en_route,
          NotificationTrigger.donation_partner_en_route_to_store,
          NotificationTrigger.donation_partner_send_quote,
          NotificationTrigger.donation_partner_skip_payment,
          NotificationTrigger.donation_payment_complete,
          NotificationTrigger.donation_payment_failure,
          NotificationTrigger.donation_refund,
          NotificationTrigger.donation_review,
          NotificationTrigger.donation_setup_complete,
          NotificationTrigger.donation_submit,
          NotificationTrigger.donation_update,
          NotificationTrigger.donation_timed_assign,
          NotificationTrigger.partner_reminder_low,
          NotificationTrigger.partner_reminder_moderate,
          NotificationTrigger.partner_reminder_high,
          NotificationTrigger.partner_reminder_urgent,
          NotificationTrigger.donor_unconfirmed_reminder,
          NotificationTrigger.partner_assigned_reminder,
          NotificationTrigger.partner_accepted_reminder,
          NotificationTrigger.code_lookup,
          NotificationTrigger.reset_password,
          NotificationTrigger.donation_update_eta,
          NotificationTrigger.donation_code_update_request_eta,
          NotificationTrigger.donation_auto_cancel,
        ];
      default:
        return [];
    }
  }

  public static notificationTriggerTargets(
    trigger: NotificationTrigger,
    filter?: boolean
  ): NotificationTarget[] {
    switch (trigger) {
      case NotificationTrigger.donation_auth_complete:
      case NotificationTrigger.donation_charity_assign:
      case NotificationTrigger.donation_checkout_payment:
      case NotificationTrigger.donation_checkout_setup:
      case NotificationTrigger.donation_code_payment:
      case NotificationTrigger.donation_code_update:
      case NotificationTrigger.donation_create:
      case NotificationTrigger.donation_donor_accept:
      case NotificationTrigger.donation_donor_cancel:
      case NotificationTrigger.donation_donor_confirm:
      case NotificationTrigger.donation_donor_reschedule:
      case NotificationTrigger.donation_manual_payment:
      case NotificationTrigger.donation_offline_payment:
      case NotificationTrigger.donation_partner_accept:
      case NotificationTrigger.donation_partner_arrive:
      case NotificationTrigger.donation_partner_assign:
      case NotificationTrigger.donation_partner_complete:
      case NotificationTrigger.donation_partner_decline:
      case NotificationTrigger.donation_partner_en_route:
      case NotificationTrigger.donation_partner_send_quote:
      case NotificationTrigger.donation_partner_skip_payment:
      case NotificationTrigger.donation_payment_complete:
      case NotificationTrigger.donation_payment_failure:
      case NotificationTrigger.donation_refund:
      case NotificationTrigger.donation_review:
      case NotificationTrigger.donation_setup_complete:
      case NotificationTrigger.donation_submit:
      case NotificationTrigger.donation_update:
      case NotificationTrigger.donation_timed_assign:
      case NotificationTrigger.donation_code_update_request_eta:
      case NotificationTrigger.donation_update_eta:
      case NotificationTrigger.donation_auto_cancel:
        return [
          NotificationTarget.donation_donor,
          NotificationTarget.donation_partner_primary_contact,
          NotificationTarget.donation_partner_billing_contact,
          NotificationTarget.donation_charity_primary_contact,
          NotificationTarget.donation_charity_driver_contact,
          NotificationTarget.donation_charity_website_contact,
          NotificationTarget.donation_store_primary_contact,
          NotificationTarget.donation_partner_users,
          NotificationTarget.donation_driver_users,
          NotificationTarget.current_user,
        ];
      case NotificationTrigger.donation_partner_cancel:
        return [
          NotificationTarget.donation_donor,
          NotificationTarget.donation_partner_primary_contact,
          NotificationTarget.donation_partner_billing_contact,
          NotificationTarget.donation_charity_primary_contact,
          NotificationTarget.donation_charity_driver_contact,
          NotificationTarget.donation_charity_website_contact,
          NotificationTarget.donation_store_primary_contact,
          NotificationTarget.donation_partner_users,
          NotificationTarget.donation_driver_users,
          NotificationTarget.current_user,
          NotificationTarget.previous_partner_primary_contact,
        ];
      case NotificationTrigger.donation_partner_arrive_at_store:
      case NotificationTrigger.donation_partner_en_route_to_store:
        return [
          NotificationTarget.donation_donor,
          NotificationTarget.donation_partner_primary_contact,
          NotificationTarget.donation_partner_billing_contact,
          NotificationTarget.donation_charity_primary_contact,
          NotificationTarget.donation_charity_driver_contact,
          NotificationTarget.donation_charity_website_contact,
          NotificationTarget.donation_store_primary_contact,
          NotificationTarget.donation_partner_users,
          NotificationTarget.donation_driver_users,
          NotificationTarget.current_user,
          NotificationTarget.previous_store_primary_contact,
        ];
      case NotificationTrigger.partner_reminder_low:
      case NotificationTrigger.partner_reminder_moderate:
      case NotificationTrigger.partner_reminder_high:
      case NotificationTrigger.partner_reminder_urgent:
        return [
          NotificationTarget.donation_donor,
          NotificationTarget.donation_partner_primary_contact,
          NotificationTarget.donation_partner_billing_contact,
          NotificationTarget.donation_charity_primary_contact,
          NotificationTarget.donation_charity_driver_contact,
          NotificationTarget.donation_charity_website_contact,
          NotificationTarget.donation_store_primary_contact,
          NotificationTarget.current_user,
        ];
      case NotificationTrigger.donor_unconfirmed_reminder:
        return [NotificationTarget.donation_donor];
      case NotificationTrigger.partner_assigned_reminder:
      case NotificationTrigger.partner_accepted_reminder:
        return [
          NotificationTarget.partner_primary_contact,
          NotificationTarget.partner_billing_contact,
          NotificationTarget.partner_partner_users,
          NotificationTarget.partner_driver_users,
        ];
      case NotificationTrigger.code_lookup:
        return [NotificationTarget.donor];
      case NotificationTrigger.reset_password:
        return [NotificationTarget.user];
      default:
        return !filter ? [] : Object.values(NotificationTarget);
    }
  }
}

export interface INotification extends IBaseDTO {
  id: string;
  target: NotificationTarget;
  trigger: NotificationTrigger;
  template?: string;
  subject?: string;
  targetType: NotificationTargetType;
}
